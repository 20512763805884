export interface ThriveNotification {
    type: NotificationType
}
export class AccountNotification implements ThriveNotification {
    type: NotificationType;
    constructor() {

    }
}
export enum NotificationType {
    NoBookingDates = "NoBookingDates",
    NoHourlyRateSet = "NoHourlyRateSet",
    NoProfilePicture = "NoProfilePicture",
    NoPersonalBio = "NoPersonalBio",

}