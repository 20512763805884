import { Injectable } from "@angular/core";
import { Firestore, addDoc, collection, collectionData, doc, getDoc, query, updateDoc, where } from "@angular/fire/firestore";
import * as moment from 'moment';
import { Observable, lastValueFrom } from "rxjs";
import { map } from "rxjs/operators";
import { Conversation, ConversationMessage } from '../models/conversation.model';
@Injectable({
  providedIn: 'root',
})
export class ConversationsService {

  constructor(
    public afs: Firestore
  ) { }

  async getConversationById(docId: string): Promise<Conversation> {
    const snap = await getDoc(doc(this.afs, 'conversations', docId))
    return this.getConversationObj(snap.data());

  }

  async getConversationsForUserId(userId: string): Promise<Observable<Conversation[]>> {
    const conversations = [];
    const $conversations = collectionData(query(collection(this.afs, "conversations"), where('members', 'array-contains', userId))).pipe(map(data => {
      data.forEach(qr => {
        const conversation = this.getConversationObj(qr);
        conversations.push(conversation);
      });
      return conversations;
    }));
    return $conversations;

  }

  getUnReadConversationsCountForUserId(userId: string) {
    let conversationsCount = 0;
    return collectionData(query(collection(this.afs, "conversations"), where('members', 'array-contains', userId))).pipe(map(data => {
      data.forEach(qr => {
        const convo = qr;
        const msgs = convo.messages;
        for (var i = 0; i < msgs?.length; i++) {
          if (msgs[i].toUserId == userId && !msgs[i].isRead) {
            conversationsCount += 1;
          }
        }
      });
      return conversationsCount;
    }));

  }

  async insertNewConversation(conversation: Conversation): Promise<boolean> {
    try {
      const msgs = []
      for (let i = 0; i < conversation.messages.length; i++) {
        msgs.push(Object.assign({}, conversation.messages[i]));
      }
      conversation.messages = msgs;
      const convoModel = Object.assign({}, conversation);
      const ref =
        collection(this.afs, "conversations");
      const newDoc = await addDoc(ref, convoModel);
      convoModel.docId = newDoc.id
      const docReference = doc(
        this.afs,
        `conversations/${convoModel.docId}`
      );
      await updateDoc(docReference, { ...convoModel });

    } catch (e) {
      console.log('create conversation ', e);
      return false;
    }
    return true;
  }

  async updateConversation(conversation: Conversation) {
    try {
      const msgs = []
      for (let i = 0; i < conversation.messages.length; i++) {
        msgs.push(Object.assign({}, conversation.messages[i]));
      }
      conversation.messages = msgs;


      const conversationDoc = doc(this.afs, `conversations/${conversation.docId}`);
      const data = Object.assign({}, conversation);
      await updateDoc(conversationDoc, { ...data });
    } catch (e) {
      console.log('create conversation ', e);
      return false;
    }
    return true;
  }

  getConversationObj(qr): Conversation {
    let convo;
    if (qr !== undefined) {
      if (qr.data) {
        convo = qr.data();
      } else {
        convo = qr;
      }
      const conversation = new Conversation();
      conversation.id = convo.id;
      conversation.docId = convo.docId;
      conversation.members = convo.members;
      const messages = [];
      convo.messages.forEach(data => {
        const message = new ConversationMessage();
        message.from = data.from;
        message.fromUserId = data.fromUserId;
        message.isRead = data.isRead;
        message.subject = data.subject ?? '';
        message.message = data.message;
        message.fromProfilePicture = data.fromProfilePicture;
        message.sentDate = data.sentDate.toDate();
        message.to = data.to;
        message.toUserId = data.toUserId;
        messages.push(message);
      });
      conversation.messages = messages;

      return conversation;
    }
    return undefined;
  }

}
