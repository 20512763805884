import { v4 as uuidv4 } from 'uuid';
export class Booking {
  constructor() {
    this.id = uuidv4();
  }
  id: string;
  docId: string;
  startDateTime: Date;
  endDateTime: Date;

  dateStr: string;
  instructorId: string;
  instructorName: string;
  studentId: string;
  studentName: string;
  temporarilyBooked: boolean;
  bookedDate: Date;
  amount: number;
  notes: string;
  sessionStartTime:string;
  sessionDurationSeconds:string;
}
