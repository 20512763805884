import { Injectable } from '@angular/core';
import { Firestore, collection, collectionData, doc, docData, endAt, orderBy, query, startAt, updateDoc, where } from '@angular/fire/firestore';
import { BehaviorSubject, from, Observable, of, combineLatest, lastValueFrom } from 'rxjs';
import { distinct, map, mergeMap, scan, toArray } from 'rxjs/operators';

import { User, UserCommunications } from '../models/user';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    public afs: Firestore,   // Inject Firestore service
    // NgZone service to remove outside scope warning
  ) { }

  createUser(user: User) {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const docRef = doc(this.afs, `users/${user.userId}`);
        const userObj = Object.assign({}, user);
        await updateDoc(docRef, { ...userObj });
        resolve(true);
      } catch (e) {
        console.log('create user ', e);
        resolve(false);
      }
    });
  }

  checkUserEmailExistsObservable(email: string) {
    const snapShot = collectionData(query(collection(this.afs, 'users'), where('email', '==', email)));
    return snapShot;
  }

  getUser(userId: string) {
    if (userId !== undefined) {
      return docData(doc(this.afs, `users/${userId}`)).pipe(map(userDoc => {
        const user = new User();
        user.userId = userId;
        user.profilePicture = userDoc?.profilePicture ?? null;
        user.email = userDoc?.email;
        user.dateJoined = userDoc?.dateJoined;
        user.lastName = userDoc?.lastName;
        user.middleName = userDoc?.middleName;
        user.firstName = userDoc?.firstName;
        user.location = userDoc?.location ?? '';
        user.socialLinks = userDoc?.socialLinks ?? {};
        user.webLinks = userDoc?.webLinks ?? [];
        user.isInstructor = userDoc?.isInstructor ?? false;
        user.awards = userDoc?.awards ?? [];
        user.bio = userDoc?.bio ?? '';
        user.education = userDoc?.education ?? '';
        user.experience = userDoc?.experience ?? '';
        user.onboardingCompleted = userDoc?.onboardingCompleted ?? false;
        user.ratePerHour = userDoc?.ratePerHour ?? 0;
        user.isCurrentlyAthlete = userDoc?.isCurrentlyAthlete ?? false;
        user.onboardingStepsCompleted = userDoc?.onboardingStepsCompleted ?? 0;
        user.acceptsClients = userDoc?.acceptsClients ?? false;
        user.timeRanges = userDoc?.timeRanges ?? [];
        user.timeZone = userDoc?.timeZone ?? '';
        user.datesUnavailable = userDoc?.datesUnavailable ?? [];
        user.fbAccessToken = userDoc?.fbAccessToken ?? '';
        user.instagramAccessToken = userDoc?.instagramAccessToken ?? '';
        user.joinedWithGoogle = userDoc?.joinedWithGoogle ?? false;
        user.joinedWithFacebook = userDoc?.joinedWithFacebook ?? false;
        user.interests = userDoc?.interests ?? [];
        user.skills = userDoc?.skills ?? [];
        user.questionaireResponses = userDoc?.questionaireResponses ?? [];
        user.playerQuestionaireComplete = userDoc?.playerQuestionaireComplete ?? false;
        user.streetAddress1 = userDoc?.streetAddress1 ?? '';
        user.streetAddress2 = userDoc?.streetAddress2 ?? '';
        user.city = userDoc?.city ?? '';
        user.state = userDoc?.state ?? '';
        user.zipCode = userDoc?.zipCode ?? '';
        user.userCommunications = new UserCommunications(
          userDoc?.receiveAccountEmailNotifications ?? true,
          userDoc?.receiveAccountTextNotifications ?? true,
          userDoc?.receiveMarketingEmailNotifications ?? false,
          userDoc?.receiveMarketingTextNotifications ?? false,
          userDoc?.receiveInstructorEmailNotifications ?? true,
          userDoc?.receiveInstructorTextNotifications ?? true
        );
        user.userMessaging = userDoc?.userMessaging;
        user.isThriveAdmin = userDoc?.isThriveAdmin;
        user.approvedAsInstructor = userDoc?.approvedAsInstructor??false;
        user.rejectedAsInstructor = userDoc?.rejectedAsInstructor?? false;
        return user;
      }));

    } else {
      console.log('userId was undefined');
      return undefined;
    }
  }

  getUserAsObservable(userId: string): Observable<any> {
    if (userId !== undefined) {
      return docData(doc(this.afs, `users/${userId}`));

    } else {
      console.log('userId was undefined');
      // throw new Error('getUser userId === undefined');
    }
  }

  async updateUser(user: User) {
    try {

      const ref = doc(this.afs, `users/${user.userId}`);
      await updateDoc(ref, {
        acceptsClients: user.acceptsClients ?? false,
        fbUserId: user.fbUserId ?? '',
        firstName: user.firstName ?? '',
        middleName: user.middleName ?? '',
        lastName: user.lastName ?? '',
        fbAccessToken: user.fbAccessToken ?? '',
        profilePicture: user.profilePicture ?? '',
        email: user.email,
        webLinks: user.webLinks ?? [],
        experience: user.experience ?? '',
        bio: user.bio ?? '',
        education: user.education ?? '',
        location: user.location ?? '',
        approvedAsInstructor: user?.approvedAsInstructor,
        rejectedAsInstructor: user?.rejectedAsInstructor,
        appointmentUpdateEmail: user.appointmentUpdateEmail ?? false,
        appointmentUpdateSms: user.appointmentUpdateSms ?? false,
        accountUpdateEmail: user.accountUpdateEmail ?? false,
        accountUpdateSms: user.accountUpdateSms ?? false,
        marketingUpdateEmail: user.marketingUpdateEmail ?? false,
        timeRanges: user.timeRanges ?? [],
        tiktokHashTags: user.tiktokHashTags ?? '',
        instagramHashTags: user.instagramHashTags ?? '',
        twitterHashTags: user.twitterHashTags ?? '',
        facebookHashTags: user.facebookHashTags ?? '',
        linkedinHashTags: user.linkedinHashTags ?? '',
        interests: user.interests ?? [],
        skills: user.skills ?? [],
        instagramAccessToken: user.instagramAccessToken ?? '',
        onboardingCompleted: user.onboardingCompleted ?? false,
        datesUnavailable: user.datesUnavailable ?? [],
        ratePerHour: user.ratePerHour ?? 0,
        timeZone: user.timeZone ?? '',
        isInstructor: user.isInstructor ?? false,
        questionaireResponses: user.questionaireResponses ?? [],
        playerQuestionaireComplete: user.playerQuestionaireComplete ?? false,
        streetAddress1: user.streetAddress1 ?? '',
        streetAddress2: user.streetAddress2 ?? '',
        city: user.city ?? '',
        state: user.state ?? '',
        zipCode: user.zipCode ?? '',
        // userCommunications: {
        //   receiveAccountEmailNotifications: user.userCommunications?.receiveAccountEmailNotifications ?? true,
        //   receiveAccountTextNotifications: user.userCommunications?.receiveAccountTextNotifications ?? true,
        //   receiveMarketingEmailNotifications: user.userCommunications?.receiveMarketingEmailNotifications ?? false,
        //   receiveMarketingTextNotifications: user.userCommunications?.receiveMarketingTextNotifications ?? false,
        //   receiveInstructorEmailNotifications: user.userCommunications?.receiveInstructorEmailNotifications ?? true,
        //   receiveInstructorTextNotifications: user.userCommunications?.receiveInstructorTextNotifications ?? true
        // },
        // userMessaging: (user.userMessaging ?? []).map(ele => { return { type: ele.type, message: ele.message } })
      })

    } catch (e) {
      console.log(e);
    }

  }

  searchInstructor(term: string, skill?: any): Observable<any> {
    const results = [];
    const fSearch = collectionData(query(collection(this.afs, 'users',), where('isInstructor', '==', true), where('acceptsClients', '==', true), orderBy('firstName  ', 'asc'), startAt(term), endAt(term + "\uf8ff")))
    //const lSearch = this.afs.collection('users', ref => ref.where('isInstructor', '==', true).where('acceptsClients', '==', true).where('ratePerHour', '!=', 0).where('lastName', 'in', [term])).valueChanges();
    // attempt case insentive search
    const lSearch = collectionData(query(collection(this.afs, 'users'), where('isInstructor', '==', true), where('acceptsClients', '==', true), orderBy('lastName', 'asc'), startAt(term), endAt(term + "\uf8ff")))
    const areaSearch = collectionData(query(collection(this.afs, 'users'), where('isInstructor', '==', true), where('acceptsClients', '==', true), where('skills', 'array-contains', { interest: `"${skill.interest}"` })));
    const combinedList = combineLatest([fSearch, lSearch, areaSearch]).pipe(
      map(arr => {
        console.log('result ', arr)
        return arr.reduce((acc, cur) => {
          const ele = acc.find(e => e['userId'] == (cur?.length > 0 && cur[0]['userId']));
          if (!ele) {
            return acc.concat(cur);
          }
          return acc;
        });
      }),
    );
    return combinedList;
  }

  searchInstructorWithSubInterest(term: string, skill?: any, subSkill?: string): Observable<any> {
    const results = [];
    const fSearch = collectionData(query(collection(this.afs, 'users'), where('isInstructor', '==', true), where('acceptsClients', '==', true), where('ratePerHour', '!=', 0), where('firstName', 'in', [term])));
    //const lSearch = this.afs.collection('users', ref => ref.where('isInstructor', '==', true).where('acceptsClients', '==', true).where('ratePerHour', '!=', 0).where('lastName', 'in', [term])).valueChanges();
    // attempt case insentive search
    const lSearch = collectionData(query(collection(this.afs, 'users'), where('isInstructor', '==', true), where('acceptsClients', '==', true), orderBy('lastName', 'asc'), startAt(term), endAt(term + "\uf8ff")));
    const areaSearch = collectionData(query(collection(this.afs, 'users'), where('isInstructor', '==', true)
      , where('acceptsClients', '==', true)
      , where('ratePerHour', '!=', 0)
      , where('skills', 'array-contains', `"${skill.interest}"`)));
    const combinedList = combineLatest([fSearch, lSearch, areaSearch]).pipe(
      map(arr => {
        return arr.reduce((acc, cur) => {
          const ele = acc.find(e => e['userId'] == (cur?.length > 0 && cur[0]['userId']));
          const sub = cur.filter(e => {
            for (var i = 0; i < e['skills']?.length; i++) {
              if (e['skills'][i]['focuses']?.indexOf(subSkill) >= 0) {
                return true;
              }
            };
            return false;
          });
          if (!ele && sub?.length > 0) {
            return acc.concat(sub);
          }
          return acc;
        });
      }),
    );
    return combinedList;
  }

  getNewPotentialInstructors() {
    return collectionData(query(collection(this.afs, "users"), where('approvedAsInstructor', '==', false))).pipe(map(data => {
      const users = [];
      data.forEach(qr => {
        const user = this.getUserObj(qr);
        users.push(user);
      });
      return users;
    }));
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  getUserObj(userDoc) {
    const user = new User();
    user.userId = userDoc.userId;
    user.profilePicture = userDoc?.profilePicture ?? null;
    user.email = userDoc?.email;
    user.dateJoined = userDoc?.dateJoined;
    user.lastName = userDoc?.lastName;
    user.middleName = userDoc?.middleName;
    user.firstName = userDoc?.firstName;
    user.location = userDoc?.location ?? '';
    user.socialLinks = userDoc?.socialLinks ?? {};
    user.webLinks = userDoc?.webLinks ?? [];
    user.isInstructor = userDoc?.isInstructor ?? false;
    user.awards = userDoc?.awards ?? [];
    user.bio = userDoc?.bio ?? '';
    user.education = userDoc?.education ?? '';
    user.experience = userDoc?.experience ?? '';
    user.onboardingCompleted = userDoc?.onboardingCompleted ?? false;
    user.ratePerHour = userDoc?.ratePerHour ?? 0;
    user.isCurrentlyAthlete = userDoc?.isCurrentlyAthlete ?? false;
    user.onboardingStepsCompleted = userDoc?.onboardingStepsCompleted ?? 0;
    user.acceptsClients = userDoc?.acceptsClients ?? false;
    user.timeRanges = userDoc?.timeRanges ?? [];
    user.timeZone = userDoc?.timeZone ?? '';
    user.datesUnavailable = userDoc?.datesUnavailable ?? [];
    user.fbAccessToken = userDoc?.fbAccessToken ?? undefined;
    user.instagramAccessToken = userDoc?.instagramAccessToken ?? undefined;
    user.joinedWithGoogle = userDoc?.joinedWithGoogle ?? undefined;
    user.joinedWithFacebook = userDoc?.joinedWithFacebook ?? undefined;
    user.interests = userDoc?.interests ?? [];
    user.skills = userDoc?.skills ?? [];
    user.questionaireResponses = userDoc?.questionaireResponses ?? [];
    user.playerQuestionaireComplete = userDoc?.playerQuestionaireComplete ?? false;
    user.streetAddress1 = userDoc?.streetAddress1 ?? '';
    user.streetAddress2 = userDoc?.streetAddress2 ?? '';
    user.city = userDoc?.city ?? '';
    user.state = userDoc?.state ?? '';
    user.zipCode = userDoc?.zipCode ?? '';
    user.userCommunications = new UserCommunications(
      userDoc?.receiveAccountEmailNotifications ?? true,
      userDoc?.receiveAccountTextNotifications ?? true,
      userDoc?.receiveMarketingEmailNotifications ?? false,
      userDoc?.receiveMarketingTextNotifications ?? false,
      userDoc?.receiveInstructorEmailNotifications ?? true,
      userDoc?.receiveInstructorTextNotifications ?? true
    );
    user.userMessaging = userDoc?.userMessaging;
    user.isThriveAdmin = userDoc?.isThriveAdmin;
    user.approvedAsInstructor = userDoc?.approvedAsInstructor;
    user.rejectedAsInstructor = userDoc?.rejectedAsInstructor

    return user;
  }
}
