import { Page404Component } from './authentication/page404/page404.component';
import { AuthLayoutComponent } from './layout/app-layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layout/app-layout/main-layout/main-layout.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guard/auth.guard';
const routes: Routes = [
  {
    path: '',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('./pages/landing/landing.module').then((m) => m.LandingModule),
  },
  {
    path: 'welcome',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('./pages/landing/landing.module').then((m) => m.LandingModule),
  },
  {
    path: 'privacy',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('./pages/privacy/privacy.module').then((m) => m.PrivacyModule),
  },
  {
    path: 'instagram-auth-callback',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('./pages/instagram-auth-callback/instagram-auth-callback.module').then((m) => m.InstagramAuthCallbackModule),
  },
  {
    path: '',
    component: MainLayoutComponent,

    runGuardsAndResolvers: 'always',
    children: [
      { path: '', redirectTo: '/', pathMatch: 'full' },
      {
        path: 'home',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./pages/player/player.module').then((m) => m.PlayerModule),
      },
      {
        path: 'schedule',
        loadChildren: () =>
          import('./pages/schedule/schedule.module').then((m) => m.ScheduleModule),
      },
      {
        path: 'messages',
        loadChildren: () =>
          import('./pages/messages/messages.module').then((m) => m.MessagesModule),
      },
      {
        path: 'feed',
        loadChildren: () =>
          import('./pages/feed/feed.module').then((m) => m.FeedModule),
      },
      {
        path: 'interests',
        loadChildren: () =>
          import('./pages/join-meeting/join-meeting.module').then((m) => m.JoingMeetingModule),
      },
      {
        path: 'join-meeting/:bookingDocId',
        loadChildren: () =>
          import('./pages/join-meeting/join-meeting.module').then((m) => m.JoingMeetingModule),
      },
      {
        path: 'meeting-room',
        loadChildren: () =>
          import('./pages/meeting-room/meeting-room.module').then((m) => m.MeetingRoomModule),
      },
      {
        path: 'thrive-admin',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./pages/admin-dashboard/admin-dashboard.module').then((m) => m.AdminDashboardModule),
      },
      {
        path: 'instructor',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./pages/instructor/instructor.module').then((m) => m.InstructorModule),
      },
      {
        path: 'earnings',
        loadChildren: () =>
          import('./pages/earnings/earnings.module').then((m) => m.EarningsModule),
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('./pages/profile/user-profile.module').then((m) => m.UserProfileModule),
      },
      {
        path: 'instructor-profile',
        loadChildren: () =>
          import('./pages/instructor-profile/instructor-profile.module').then((m) => m.InstructorProfileModule),
      },
      {
        path: 'book-lesson',
        loadChildren: () =>
          import('./pages/book-lesson/book-lesson.module').then((m) => m.BookLessonModule),
      },
      {
        path: 'book-success',
        loadChildren: () =>
          import('./pages/book-success/book-success.module').then((m) => m.BookSuccessModule),
      },
      {
        path: 'search',
        loadChildren: () =>
          import('./pages/instructor-search/instructor-search.module').then((m) => m.InstructorSearchModule),
      }

    ],
  },

  {
    path: 'authentication',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      ),
  },
  { path: '**', component: Page404Component },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule { }
