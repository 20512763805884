import { Injectable, inject } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';
import { Auth, User, user } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  private auth: Auth = inject(Auth);
  user$ = user(this.auth);
  constructor(private authService: AuthService, private router: Router, private userService: UserService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    console.log('auth guard');
    return this.user$.pipe(map((aUser: User | null) => {
      if (aUser == null) {
        this.router.navigate(['/welcome']);
        return false;
      }
      return true;
    }));

    // return this.authService.currentUser.pipe(filter((myUser)=>!!myUser),map(((user) => {
    //   console.log('AUTH USER ', user)
    //   if(user == null) {
    //     debugger;
    //   }
    //   if (!user) {
    //     return false
    //   }
    //   return true;
    // })));

  }
  // return this.authService.currentUserSubject.pipe(map((user) => {
  //   if (!user) {
  //    return this.router.parseUrl('');

  //   } else if (!user.onboardingCompleted) {
  //    return  this.router.parseUrl('/authentication/onboarding');

  //   }
  //   return true;
  // }));
  // return this.authService.afAuth.authState.pipe(
  //   map(authState => {
  //     console.log('authSTate ', authState);
  //     if (!authState) {
  //       this.router.navigate(['']);
  //       return false;
  //     }
  //     this.checkOnboardingStatus(authState.uid);

  //     return false;
  //   }),
  //   map(auth => {
  //     if (!auth) {
  //       this.router.navigate(['']);
  //     }
  //     return false;
  //   }),
  // );

}
//canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
// this.authService.currentUser.subscribe(user=>{})
// if (this.authService.currentUserValue) {
//   return true;
// } else if (!this.authService.isLoggedIn()) {
//   this.router.navigate(['/authentication/signin']);
// }
// console.log('user guard is logged in true');
// return true;
//}

