<div>
  <!-- Left Sidebar -->
  <aside id="leftsidebar" class="sidebar" (mouseenter)="mouseHover($event)" (mouseleave)="mouseOut($event)">
    <!-- Menu -->
    <div class="menu">
      <ul class="list"
        [ngStyle]="{'position' : 'relative', 'max-height' : listMaxHeight+'px', 'max-width' : listMaxWidth+'px'}"
        [perfectScrollbar]>
        <li class="sidebar-user-panel">
          <div class="user-panel">
            <div class=" image">
              <img *ngIf="userImg && userImg?.length>0" src="{{userImg}}" class="img-circle user-img-circle"
                alt="User Image" routerLink="/profile" />

            </div>
            <div class="d-flex justify-content-center">

              <i *ngIf="!userImg || userImg?.length ==0" class="fas fa-user img-circle user-img-circle link icon"
                routerLink="/profile"></i>

            </div>

          </div>
          <div class="profile-usertitle">
            <div class="sidebar-userpic-name">{{user?.firstName}} {{user?.lastName}}</div>
          </div>
          <div class="row " *ngIf="user?.isInstructor">
            <div class="col-12">
              <div class="d-flex justify-content-center" *ngIf="user?.isInstructor">
                <ng-template #goLiveContent>You are currently no searchable. Click the <b>Go Live</b> button to start
                  receiving bookings!</ng-template>
                <button class="msr-3 bg-dol-green go-live" [ngbTooltip]="goLiveContent" tooltipClass="tooltip-bg"
                  mat-raised-button [disabled]="goLiveIsDisabled" (click)="goLive()" *ngIf="!userIsLive">Go
                  Live</button>
                <ng-template #unLiveContent>
                  <span stlye="red">You are currently Live. Click the <b>Live</b> button to stop
                    receiving bookings!</span>
                </ng-template>
                <button [ngbTooltip]="unLiveContent" tooltipClass="tooltip-bg" class="msr-3 bg-dol-green go-live"
                  mat-raised-button (click)="goUnLive()" *ngIf="userIsLive">Live</button>
              </div>
            </div>
          </div>
        </li>
        <ng-container *ngFor="let sidebarItem of sidebarItems">
          <li [class.active]="level1Menu === sidebarItem.moduleName && sidebarItem.submenu.length != 0"
            [routerLinkActive]="sidebarItem.submenu.length != 0 ? '' : 'active'"
            *ngIf="sidebarItem.path =='/instructor' && user.isInstructor && user.approvedAsInstructor">
            <a [routerLink]="sidebarItem.class === '' ? [sidebarItem.path] : null" *ngIf="!sidebarItem.groupTitle;"
              [ngClass]="[sidebarItem.class]" (click)="callLevel1Toggle($event, sidebarItem.moduleName)"
              class="menu-top">
              <i [ngClass]="[sidebarItem.iconType]">{{sidebarItem.icon}}</i>

              <span class="hide-menu">
                {{sidebarItem.title}}
              </span>

              <span *ngIf="sidebarItem.badge !== '' " [ngClass]="[sidebarItem.badgeClass]">{{sidebarItem.badge}}</span>
            </a>
          </li>
          <!-- Top Most level menu -->
          <li [class.active]="level1Menu === sidebarItem.moduleName && sidebarItem.submenu.length != 0"
            [routerLinkActive]="sidebarItem.submenu.length != 0 ? '' : 'active'"
            *ngIf="sidebarItem.path !='/instructor'">
            <div class="header" *ngIf="sidebarItem.groupTitle === true">{{sidebarItem.title}}</div>
            <a [routerLink]="sidebarItem.class === '' ? [sidebarItem.path] : null" *ngIf="!sidebarItem.groupTitle;"
              [ngClass]="[sidebarItem.class]" (click)="callLevel1Toggle($event, sidebarItem.moduleName)"
              class="menu-top">
              <i [ngClass]="[sidebarItem.iconType]">{{sidebarItem.icon}}</i>
              <span class="hide-menu" *ngIf="sidebarItem.path =='/instructor' && !user.isInstructor">
                Become instructor
              </span>
              <span class="hide-menu" *ngIf="sidebarItem.path =='/instructor' && user.isInstructor">
                {{sidebarItem.title}}
              </span>
              <span *ngIf="sidebarItem.path !='/instructor'">
                {{sidebarItem.title}}
              </span>
              <span *ngIf="sidebarItem.badge !== '' " [ngClass]="[sidebarItem.badgeClass]">{{sidebarItem.badge}}</span>
            </a>
            <!-- First level menu -->
            <ul class="ml-menu" *ngIf="sidebarItem.submenu.length > 0">
              <li [ngClass]="level2Menu === sidebarSubItem.moduleName? 'activeSub':''"
                *ngFor="let sidebarSubItem of sidebarItem.submenu"
                [routerLinkActive]="sidebarSubItem.submenu.length > 0 ? '' : 'active'">
                <a [routerLink]="sidebarSubItem.submenu.length > 0 ? null : [sidebarSubItem.path]"
                  (click)="callLevel2Toggle($event,sidebarSubItem.moduleName)" [ngClass]="[sidebarSubItem.class]">
                  {{sidebarSubItem.title}}
                </a>
                <!-- Second level menu -->
                <ul class="ml-menu-2" *ngIf="sidebarSubItem.submenu.length > 0">
                  <li *ngFor="let sidebarSubsubItem of sidebarSubItem.submenu"
                    [ngClass]="level3Menu === sidebarSubsubItem.moduleName? 'activeSubSub':''"
                    [routerLinkActive]="sidebarSubsubItem.submenu.length > 0 ? '' : 'active'">
                    <a [routerLink]="sidebarSubsubItem.submenu.length > 0 ? null : [sidebarSubsubItem.path]"
                      (click)="callLevel3Toggle($event,sidebarSubsubItem.moduleName)"
                      [ngClass]="[sidebarSubsubItem.class]">
                      {{sidebarSubsubItem.title}}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ng-container>
      </ul>
    </div>
    <!-- #Menu -->
  </aside>
  <!-- #END# Left Sidebar -->
</div>
