import { Router, NavigationEnd } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  Renderer2,
  HostListener,
  OnDestroy,
} from '@angular/core';
import { ROUTES } from './sidebar-items';
import { AuthService } from 'src/app/core/services/auth.service';
import { User } from 'src/app/core/models/user';
import { UserService } from 'src/app/core/services/user.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { Subscribable, Subscription } from 'rxjs';
import { ConversationsService } from 'src/app/core/services/conversations.service';
import { BookingsService } from 'src/app/core/services/bookings.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  public sidebarItems: any[];
  level1Menu = '';
  level2Menu = '';
  level3Menu = '';
  public innerHeight: any;
  public bodyTag: any;
  listMaxHeight: string;
  listMaxWidth: string;
  userFullName: string;
  userImg: string;
  userType: string;
  headerHeight = 60;
  routerObj = null;
  currentRoute: string;
  isInstructorView: boolean;
  user: User;
  goLiveIsDisabled = true;
  userIsLive = false;
  subscriptionRef: Subscription;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private authService: AuthService,
    private router: Router,
    private userService: UserService,
    private notificationService: NotificationService,
    private toastr: ToastrService,
    private conversationsService: ConversationsService,
    private bookingsService: BookingsService
  ) {
    this.user = <User>{};
    this.routerObj = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // logic for select active menu in dropdown
        const currenturl = event.url.split('?')[0];
        this.level1Menu = currenturl.split('/')[1];
        this.level2Menu = currenturl.split('/')[2];

        // close sidebar on mobile screen after menu select
        this.renderer.removeClass(this.document.body, 'overlay-open');
        if (event.url === '/instructor') {
          this.isInstructorView = true;
          this.initLeftSidebar();
        } else {
          this.isInstructorView = false;
          this.initLeftSidebar();
        }
      }

    });

    this.subscriptionRef = this.authService.currentUser?.pipe(take(2)).subscribe(user => {
      if (user) {
        this.user = user;
        this.userImg = this.user?.profilePicture ?? 'assets/images/user/default_user.png';

        console.log('sidebar ', this.user, ' ', this.user['photoURL']);
        if (this.user.acceptsClients) {
          this.userIsLive = true;
        }

        this.initLeftSidebar();
      }

    });
    this.notificationService.accountNotificationItemsObservable.subscribe(notifications => {
      this.goLiveIsDisabled = notifications.length > 0;
    });

  }

  async goLive() {
    this.user.acceptsClients = true;
    await this.userService.updateUser(this.user);
    console.log('went live ', this.user);
    this.userIsLive = true;
    this.toastr.show('You are searchable on ThriveNetwork now!');
  }

  async goUnLive() {
    this.user.acceptsClients = false;
    await this.userService.updateUser(this.user);
    console.log('went un live ', this.user);
    this.userIsLive = false;
    this.toastr.show('You are no longer searchable on ThriveNetwork now!');
  }

  @HostListener('window:resize', ['$event'])
  windowResizecall(event) {
    this.setMenuHeight();
    this.checkStatuForResize(false);
  }
  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.renderer.removeClass(this.document.body, 'overlay-open');
    }
  }

  callLevel1Toggle(event: any, element: any) {
    if (element === this.level1Menu) {
      this.level1Menu = '0';
    } else {
      this.level1Menu = element;
    }
    const hasClass = event.target.classList.contains('toggled');
    if (hasClass) {
      this.renderer.removeClass(event.target, 'toggled');
    } else {
      this.renderer.addClass(event.target, 'toggled');
    }
  }

  callLevel2Toggle(event: any, element: any) {
    if (element === this.level2Menu) {
      this.level2Menu = '0';
    } else {
      this.level2Menu = element;
    }
  }

  callLevel3Toggle(event: any, element: any) {
    if (element === this.level3Menu) {
      this.level3Menu = '0';
    } else {
      this.level3Menu = element;
    }
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.routerObj.unsubscribe();
    this.subscriptionRef.unsubscribe();
  }

  async initLeftSidebar() {
    if (this.authService.currentUserValue) {
      this.sidebarItems = ROUTES.filter((sidebarItem) => { return this.isInstructorView ? sidebarItem.view.indexOf('instructor') >= 0 : sidebarItem.view.indexOf('player') >= 0; });
    }
    if(this.user.isThriveAdmin) {
      const adminItems = ROUTES.filter(item=>item.view.indexOf('admin')>=0);
      this.sidebarItems = this.sidebarItems.concat(adminItems);
    }
    const _this = this;
    // Set menu height
    _this.setMenuHeight();
    _this.checkStatuForResize(true);
    this.bodyTag = this.document.body;
    let numMsgs = 0;
    this.conversationsService.getUnReadConversationsCountForUserId(this.user.userId).subscribe(data => {
      numMsgs = data;
    })
    this.bookingsService.getAllBookingsCountForUser(this.user.userId).subscribe(numBookings => {
      ROUTES.forEach(route => {
        if (route.moduleName == 'messages') {
          route.badge = numMsgs == 0 ? '' : numMsgs.toString();
        } else if (route.moduleName == 'schedule') {
          route.badge = numBookings == 0 ? '' : numBookings.toString();
        }
      });
    });

  }

  setMenuHeight() {
    this.innerHeight = window.innerHeight;
    const height = this.innerHeight - this.headerHeight;
    this.listMaxHeight = height + '';
    this.listMaxWidth = '500px';
  }

  isOpen() {
    return this.bodyTag.classList.contains('overlay-open');
  }

  checkStatuForResize(firstTime) {
    if (window.innerWidth < 1170) {
      this.renderer.addClass(this.document.body, 'ls-closed');
    } else {
      this.renderer.removeClass(this.document.body, 'ls-closed');
    }
  }

  mouseHover(e) {
    const body = this.elementRef.nativeElement.closest('body');
    if (body.classList.contains('submenu-closed')) {
      this.renderer.addClass(this.document.body, 'side-closed-hover');
      this.renderer.removeClass(this.document.body, 'submenu-closed');
    }
  }

  mouseOut(e) {
    const body = this.elementRef.nativeElement.closest('body');
    if (body.classList.contains('side-closed-hover')) {
      this.renderer.removeClass(this.document.body, 'side-closed-hover');
      this.renderer.addClass(this.document.body, 'submenu-closed');
    }
  }
}
