import { Interest } from "./interest.model";
import { SocialLinks } from "./social-links.model";
import { UserQuestionaire } from "./user.questionaire";

export interface SelectTime {
  dayOfWeek: string;
  start: string;
  end: string;
  date?: Date;
}

export class UserCommunications {

  receiveAccountEmailNotifications: boolean;
  receiveAccountTextNotifications: boolean;
  receiveMarketingEmailNotifications: boolean;
  receiveMarketingTextNotifications: boolean;
  receiveInstructorEmailNotifications: boolean;
  receiveInstructorTextNotifications: boolean;

  constructor(
    receiveAccountEmailNotifications,
    receiveAccountTextNotifications,
    receiveMarketingEmailNotifications,
    receiveMarketingTextNotifications,
    receiveInstructorEmailNotifications,
    receiveInstructorTextNotifications) {
    this.receiveAccountEmailNotifications = receiveAccountEmailNotifications;
    this.receiveAccountTextNotifications = receiveAccountTextNotifications;
    this.receiveMarketingEmailNotifications = receiveMarketingEmailNotifications;
    this.receiveMarketingTextNotifications = receiveMarketingTextNotifications;
    this.receiveInstructorEmailNotifications = receiveInstructorEmailNotifications;
    this.receiveInstructorTextNotifications = receiveInstructorTextNotifications;
  }

}

export class UserMessaging {
  type: string;
  message: string;
  notSaved: boolean
  constructor(type, message) {
    this.type = type;
    this.message = message;
  }
}

export class User {

  constructor() {
    this.socialLinks = new SocialLinks();
    this.joinedWithFacebook = false;
    this.joinedWithGoogle = false;
    this.acceptedTCs = false;
    this.isInstructor = false;
    this.approvedAsInstructor = false;
    this.rejectedAsInstructor = false; this.onboardingCompleted = false;
    this.isCurrentlyAthlete = false;
    this.acceptsClients = false;
    this.appointmentUpdateSms = false;
    this.appointmentUpdateEmail = false;
    this.accountUpdateEmail = false;
    this.accountUpdateSms = false;
    this.marketingUpdateEmail = false;
    this.marketingUpdateSms = false;
    this.playerQuestionaireComplete = false;
    this.instructorQuestionaireComplete = false;
    this.isThriveAdmin = false;
  }

  userId: string;
  email: string;
  password: string;
  firstName: string;
  middleName: string;
  lastName: string;
  token: string;
  fbUserId: string;
  fbAccessToken: string;
  instagramAccessToken: string;
  joinedWithFacebook: boolean;
  joinedWithGoogle: boolean;
  bio: string;
  education: string;
  experience: string;
  location: string;
  webLinks: Array<string>;
  skills: Array<Interest>;
  interests: Array<Interest>;
  socialLinks: SocialLinks;
  awards: Array<string>;
  acceptedTCs: boolean;
  isInstructor: boolean;
  approvedAsInstructor: boolean = false;
  rejectedAsInstructor: boolean = false;
  instructorAgeGroups: Array<string>;
  acceptedTCDate: Date;
  dateJoined: Date;
  profilePicture: string;
  onboardingCompleted: boolean;
  ratePerHour: number;
  isCurrentlyAthlete: boolean;
  onboardingStepsCompleted: number;
  acceptsClients: boolean;
  appointmentUpdateSms: boolean;
  appointmentUpdateEmail: boolean;
  accountUpdateSms: boolean;
  accountUpdateEmail: boolean;
  marketingUpdateEmail: boolean;
  marketingUpdateSms: boolean;
  timeRanges: SelectTime[];
  timeZone: string;
  datesUnavailable: any[];
  instagramHashTags: string;
  facebookHashTags: string;
  twitterHashTags: string;
  tiktokHashTags: string;
  linkedinHashTags: string;
  questionaireResponses: any[];
  playerQuestionaireComplete: boolean;
  instructorQuestionaireComplete: boolean;
  streetAddress1: string;
  streetAddress2: string;
  city: string;
  state: string;
  zipCode: string;
  userCommunications: UserCommunications;
  userMessaging: UserMessaging[];
  isThriveAdmin: boolean = false;
}
