import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RightSidebarService } from './services/rightsidebar.service';
import { AuthGuard } from './guard/auth.guard';
import { AuthService } from './services/auth.service';
import { DynamicScriptLoaderService } from './services/dynamic-script-loader.service';
import { throwIfAlreadyLoaded } from './guard/module-import.guard';
import { UserService } from './services/user.service';
import { BookingsService } from './services/bookings.service';
import { ConversationsService } from './services/conversations.service';
import { NotificationService } from './services/notification.service';
import { RendererService } from './services/renderer.service';
import { SocialService } from './services/social.service';
//import { TwilioService } from './services/twillio.service';

import { HttpClientModule } from '@angular/common/http';
import { MediaService } from './services/media.service';
import { TokenService } from './services/token.service';
import { TimezonePickerService } from './services/timezone-picker.service';
import { initializeAppFactory } from './app.initializer';
import { EventService } from './services/event.service';

@NgModule({

  imports: [CommonModule,
    HttpClientModule,
  ],
  providers: [
    RightSidebarService,
    AuthGuard,
    AuthService,
    DynamicScriptLoaderService,
    UserService,
    BookingsService,
    ConversationsService,
    NotificationService,
    RendererService,
    SocialService,
    TimezonePickerService,
    EventService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [AuthService],
      multi: true
    }
  ],
  declarations: [
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
