import { RouteInfo } from './sidebar.metadata';
export const ROUTES: RouteInfo[] = [{
  path: '/home',
  title: 'Player',
  moduleName: 'dashboard',
  iconType: 'material-icons-two-tone',
  icon: 'home',
  class: '',
  groupTitle: false,
  badge: '',
  badgeClass: '',
  submenu: [],
  view: 'player,instructor'
},

{
  path: '/schedule',
  title: 'Schedule',
  moduleName: 'schedule',
  iconType: 'material-icons-two-tone',
  icon: 'event_note',
  class: '',
  groupTitle: false,
  badge: '',
  badgeClass: 'badge bg-green sidebar-badge float-end',
  submenu: [],
  view: 'player'
},
// {
//   path: '/feed',
//   title: 'Feed',
//   moduleName: 'newsFeed',
//   iconType: 'material-icons-two-tone',
//   icon: 'forum',
//   class: '',
//   groupTitle: false,
//   badge: '',
//   badgeClass: '',
//   submenu: [],
//   view: 'player'
// },
{
  path: '/search',
  title: 'Search Instructors',
  moduleName: 'searchInstructor',
  iconType: 'material-icons-two-tone',
  icon: 'search',
  class: '',
  groupTitle: false,
  badge: '',
  badgeClass: '',
  submenu: [],
  view: 'player'
},
// {
//   path: '/meeting-room',
//   title: 'Meeting Room',
//   moduleName: 'meetingRoom',
//   iconType: 'material-icons-two-tone',
//   icon: 'search',
//   class: '',
//   groupTitle: false,
//   badge: '',
//   badgeClass: 'user',
//   submenu: [],
//   view: 'player,instructor'
// },
// {
//   path: '/interests',
//   title: 'Interests',
//   moduleName: 'interests',
//   iconType: 'material-icons-two-tone',
//   icon: 'favorite',
//   class: '',
//   groupTitle: false,
//   badge: '',
//   badgeClass: '',
//   submenu: [],
//   view: 'player'
// },
// {
//   path: '/connections',
//   title: 'Connections',
//   moduleName: 'connections',
//   iconType: 'material-icons-two-tone',
//   icon: 'people_outline',
//   class: '',
//   groupTitle: false,
//   badge: '',
//   badgeClass: '',
//   submenu: [],
//   view: 'player'
// },
{
  path: '/messages',
  title: 'Messages',
  moduleName: 'messages',
  iconType: 'material-icons-two-tone',
  icon: 'email',
  class: '',
  groupTitle: false,
  badge: '',
  badgeClass: 'badge bg-orange sidebar-badge float-end',
  submenu: [],
  view: 'player,instructor'
},
{
  path: '/instructor',
  title: 'Instructor View',
  moduleName: 'instructor',
  iconType: 'material-icons-two-tone',
  icon: 'person_outline',
  class: '',
  groupTitle: false,
  badge: '',
  badgeClass: '',
  submenu: [],
  view: 'player'
},
// {
//   path: '/home',
//   title: 'Player View',
//   moduleName: 'player',
//   iconType: 'material-icons-two-tone',
//   icon: 'person_outline',
//   class: '',
//   groupTitle: false,
//   badge: '',
//   badgeClass: '',
//   submenu: [],
//   view: 'instructor'
// },
// {
//   path: '/planning',
//   title: 'Planning',
//   moduleName: 'planning',
//   iconType: 'material-icons-two-tone',
//   icon: 'person_outline',
//   class: '',
//   groupTitle: false,
//   badge: '',
//   badgeClass: '',
//   submenu: [],
//   view: 'instructor'
// },
{
  path: '/revenue',
  title: 'Revenue',
  moduleName: 'revenue',
  iconType: 'material-icons-two-tone',
  icon: 'person_outline',
  class: '',
  groupTitle: false,
  badge: '',
  badgeClass: '',
  submenu: [],
  view: 'instructor'
},
{
  path: '/thrive-admin',
  title: 'Thrive Admin',
  moduleName: 'admin',
  iconType: 'material-icons-two-tone',
  icon: 'supervisor_account',
  class: '',
  groupTitle: false,
  badge: '',
  badgeClass: '',
  submenu: [],
  view: 'admin'
}
];
