<nav #navbar class="navbar active" [ngClass]="{'ios':isIOS}">
  <div class="container-fluid nav-toolbar">
    <div class="navbar-header">
      <div class="d-flex justify-content-between align-items-center">
        <a href="#" onClick="return false;" class="nav-bars bars"
          (click)="mobileMenuSidebarOpen($event,'overlay-open')"></a>
        <a class="navbar-brand logo-link" routerLink="/home">
          <!-- <img src="assets/images/logo.png" alt="" /> -->
          <span class="logo-name"><img class="logo" src="../../../assets/images/logos/logo.png" /></span>
        </a>
        <div class="navbar-nav" ngbDropdown #dropDown="ngbDropdown">
          <a href="#" onClick="return false;" class="navbar-toggle collapsed settings" onClick="return false;"
            aria-expanded="false" ngbDropdownToggle>
            <mat-icon id="settingBtn">settings</mat-icon>
          </a>
          <div ngbDropdownMenu class="notification-dropdown pullDown user_profile">
            <div class="noti-list" style="position: relative; max-width: 600px; max-height: 300px;" [perfectScrollbar]>
              <ul class="menu">
                <li class="body">
                  <ul class="user_dw_menu">
                    <li>
                      <a routerLink="/profile" (click)="dropDown.close();">
                        <mat-icon aria-hidden="false" class="msr-2">person</mat-icon>Profile
                      </a>
                    </li>
                    <li>
                      <a href="#" onClick="return false;">
                        <mat-icon aria-hidden="false" class="msr-2">feedback</mat-icon>Feedback
                      </a>
                    </li>
                    <li>
                      <a href="#" onClick="return false;">
                        <mat-icon aria-hidden="false" class="msr-2">help</mat-icon>Help
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);" (click)="logout()">
                        <mat-icon aria-hidden="false" class="msr-2">power_settings_new</mat-icon>Logout
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>


      </div>
    </div>
    <div class="collapse navbar-collapse" [ngbCollapse]="isNavbarCollapsed">
      <ul class="pull-left collapse-menu-icon">
        <li>
          <button mat-icon-button (click)="callSidemenuCollapse()" class="sidemenu-collapse">
            <mat-icon>menu</mat-icon>
          </button>
        </li>
      </ul>
      <ul class="nav navbar-nav navbar-right">

        <li class="nav-item user_profile" ngbDropdown>
          <a href="#" onClick="return false;" ngbDropdownToggle role="button"
            class="nav-notification-icons pt-1 col-blue">
            <mat-icon id="settingBtn">settings</mat-icon>
          </a>
          <div ngbDropdownMenu class="notification-dropdown pullDown">
            <div class="noti-list" style="position: relative; max-width: 600px; max-height: 300px;" [perfectScrollbar]>
              <ul class="menu">
                <li class="body">
                  <ul class="user_dw_menu">
                    <li>
                      <a routerLink="/profile">
                        <mat-icon aria-hidden="false" class="msr-2">person</mat-icon>Profile
                      </a>
                    </li>
                    <li>
                      <a href="#" onClick="return false;">
                        <mat-icon aria-hidden="false" class="msr-2">feedback</mat-icon>Feedback
                      </a>
                    </li>
                    <li>
                      <a href="#" onClick="return false;">
                        <mat-icon aria-hidden="false" class="msr-2">help</mat-icon>Help
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);" (click)="logout()">
                        <mat-icon aria-hidden="false" class="msr-2">power_settings_new</mat-icon>Logout
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <!-- #END# Tasks -->
        <!-- <li class="float-end">
          <button mat-icon-button (click)="toggleRightSidebar()" class="nav-notification-icons js-right-sidebar">
            <mat-icon id="settingBtn">settings</mat-icon>
          </button>
        </li> -->
      </ul>
    </div>
  </div>
</nav>