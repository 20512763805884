// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://192.168.86.37:4200',
  facebookAppId: '320554916344924',
  twitterApiKey: 'R8brMFoPLtBYQPGRBhcpFu5Tj',
  twitterSecret: 'RmC3c4GkoqKRQZ7au82OdQPWdKX7SxBRulJos2CRZVFPniiD6f',
  twitterBearer: 'AAAAAAAAAAAAAAAAAAAAAElwWgEAAAAAh%2BiROHnE6S6Qe6lGU6fOgT6yVGo%3DtkBip4ZxLbYdd3Og5nVggaVi3VH5La7a3MSbPNhwHXKSI0h9Lc',

  firebase: {
    apiKey: "AIzaSyDxVOxY_bX0fzefxJOkDN7xiGHjw_bIoME",
    authDomain: "thrive-e8e91.firebaseapp.com",
    projectId: "thrive-e8e91",
    storageBucket: "thrive-e8e91.appspot.com",
    messagingSenderId: "991087088490",
    appId: "1:991087088490:web:25ff8773f139819647cb13",
    measurementId: "G-HKXH21BZ0Y"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
