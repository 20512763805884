
import { Injectable, NgZone, inject } from '@angular/core';
import {  FacebookAuthProvider, GoogleAuthProvider,  authState, createUserWithEmailAndPassword, sendPasswordResetEmail, signInAnonymously, signInWithCredential, signInWithEmailAndPassword, signInWithPopup, signOut } from "@angular/fire/auth";
import { Firestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { BehaviorSubject, EMPTY, from, Observable, of, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { UserService } from './user.service';
import { Functions, httpsCallableData } from '@angular/fire/functions';
import { traceUntilFirst } from '@angular/fire/performance';
import { User as ThriveUser } from '../models/user';
import { Auth, User, user } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  private readonly userDisposable: Subscription | undefined;
  public readonly user: Observable<User | null> = EMPTY;
  public currentUserObj: ThriveUser;
  public currentUserSubject = new BehaviorSubject(null);
  public currentUser = this.currentUserSubject.asObservable();
  private auth: Auth = inject(Auth);
  user$ = user(this.auth);
  userSubscription: Subscription;
  constructor(
    public afs: Firestore,   // Inject Firestore service
    public afAuth: Auth, // Inject Firebase auth service
    public userService: UserService,
    public router: Router,
    public ngZone: NgZone,
    private fns: Functions
    // NgZone service to remove outside scope warning
  ) {

    this.userSubscription = this.user$.subscribe((aUser: User | null) => {
      //handle user state changes here. Note, that user will be null if there is no currently logged in user.
   console.log(aUser);
  })

    if (afs) {
      this.user = authState(afAuth);
      this.userDisposable = authState(this.afAuth).pipe(
        traceUntilFirst('auth'),
        map(u => !!u)
      ).subscribe(isLoggedIn => {
        console.log('logged in ', isLoggedIn)
      });
    }
 console.log(   this.afAuth.currentUser)
    /* Saving user data in localstorage when
 logged in and setting up null when logged out */
    this.afAuth.onAuthStateChanged(authUser => {
      console.log('AUTH State Change ', authUser, ' ', this.user);
      if (authUser) {

        // localStorage.setItem('currentUser', JSON.stringify(this.user));
        authUser.reload();
        console.log('url ', router.url);

        this.userService.getUserAsObservable(authUser.uid).pipe(take(1)).subscribe(data => {

          console.log('User object set ', data);
          this.currentUserSubject.next(data)

        });
      }
    });

  }

  /*
   @TODO Figure out a way to only call to get user details from firestore once
 */
  async getUser(userId: string) {
    const user = await this.userService.getUser(userId);
    this.currentUserObj
    localStorage.setItem('currentUser', JSON.stringify(user));
    return user;
  }

  public get currentUserValue(): any {
    return this.currentUserSubject?.value;
  }

  login(email: string, password: string) {
    return signInWithEmailAndPassword(this.afAuth, email, password);
  }


  changeUserPassword(passwordResetEmail: string) {
    return sendPasswordResetEmail(this.afAuth, passwordResetEmail)
      .then(() => {
        window.alert('Password reset email sent, check your inbox.');
      }).catch((error) => {
        window.alert(error)
      })
  }

  createNewAuthUser(email: string, password: string) {
    return createUserWithEmailAndPassword(this.afAuth, email, password).then(async (userRs) => {
      const us = await userRs;
      const user = new ThriveUser();
      user.userId = us.user.uid
      user.dateJoined = new Date();
      user.email = us.user.email;
      user.isInstructor = false;
      await this.userService.createUser(user);
      localStorage.setItem('currentUser', JSON.stringify(user));
      this.currentUserSubject.next(of(user));
      this.sendNewUserEmail(us.user.uid, us.user.email);
      return us.user;
    });
  }

  isLoggedIn() {
    const userStr = localStorage.getItem('currentUser');
    if (userStr && userStr.length > 0 && userStr !== 'undefined') {
      const user = JSON.parse(userStr) as User;
      console.log('USER ', user);
      if (user.uid) {
        this.currentUserSubject = new BehaviorSubject<any>(
          user
        );
        this.currentUser = this.currentUserSubject.asObservable();
        return true;
      }
      return false;
    }
    return false;
  }

  logout() {
    this.afAuth.signOut();

    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    return of({ success: false });
  }

  googleAuth(): Promise<any> {
    return this.authLogin(new GoogleAuthProvider());
  }

  facebookAuth():Promise<any> {
    const fbp = new FacebookAuthProvider();
    fbp.addScope('user_birthday,user_posts,user_photos,user_link');//instagram_graph_user_media
    fbp.setCustomParameters({
      'display': 'popup',
    });
    return this.authLogin(fbp);
  }

  authLogin(provider) {
    return new Promise((resolve, reject) => {
      signInWithPopup(this.afAuth, provider)
        .then((result) => {
          console.log('PROVIDED ', result);
          this.ngZone.run(async () => {
            let user = new ThriveUser();
            this.userService.getUser(result.user.uid).subscribe(async data => {
              const existingUser = data;
              if (existingUser?.email) {
                user = existingUser;
              }
              if (result.providerId === 'google.com') {
                user.userId = result.user.uid;
                user.joinedWithGoogle = true;
                user.dateJoined = new Date();
                user.firstName = result.user.displayName;
                user.profilePicture = result.user.photoURL;
                user.email = result.user.email;

                if (existingUser?.email) {
                  await this.userService.updateUser(user);
                } else {
                  await this.userService.createUser(user);
                }
                if (!existingUser || !existingUser?.onboardingCompleted) {
                  resolve(true);
                  this.router.navigateByUrl('/home', { state: { firstName: result.user.displayName } });
                }
              } else if (result.providerId === 'facebook.com') {
                console.log(result.user.photoURL);
                user.userId = result.user.uid;
                user.joinedWithFacebook = true;
                user.fbUserId = result.user.uid
                user.fbAccessToken = result.user.refreshToken;
                user.dateJoined = new Date();
                user.firstName = result.user.displayName;
                user.email = result.user.email;
                const pic = result.user.photoURL;
                console.log('pic ', pic);
                user.profilePicture = pic;
                if (existingUser?.email) {
                  await this.userService.updateUser(user);
                } else {
                  await this.userService.createUser(user);
                }
                resolve(true);
                this.router.navigateByUrl('/home', { state: { firstName: result.user.displayName, picture: pic } });
              }
              if (!existingUser?.onboardingCompleted) {
                this.sendNewUserEmail(result.user.uid, `${user.firstName} ${user.lastName}`).subscribe(rep => {

                });
              }
            });

          })
        }).catch((error) => {
          console.log(error);
          reject({error:error})
        })
    });
  }

  sendNewUserEmail(userId: string, nameOfUser: string) {
    const callable = httpsCallableData(this.fns, 'notifications-newUserWelcomeMessage');
    return callable({ toUserId: userId, name: nameOfUser });
  }

  parseErrorCodes(errorCode: string): string {

    let message: string;

    switch (errorCode) {
      case 'auth/wrong-password':
        message = 'Invalid login credentials.';
        break;
      case 'auth/network-request-failed':
        message = 'Please check your internet connection';
        break;
      case 'auth/too-many-requests':
        message =
          'We have detected too many requests from your device. Take a break please!';
        break;
      case 'auth/user-disabled':
        message =
          'Your account has been disabled or deleted. Please contact the system administrator.';
        break;
      case 'auth/requires-recent-login':
        message = 'Please login again and try again!';
        break;
      case 'auth/email-already-exists':
        message = 'Email address is already in use by an existing user.';
        break;
      case 'auth/user-not-found':
        message =
          'We could not find user account associated with the email address or phone number.';
        break;
      case 'auth/phone-number-already-exists':
        message = 'The phone number is already in use by an existing user.';
        break;
      case 'auth/invalid-phone-number':
        message = 'The phone number is not a valid phone number!';
        break;
      case 'auth/invalid-email  ':
        message = 'The email address is not a valid email address!';
        break;
      case 'auth/cannot-delete-own-user-account':
        message = 'You cannot delete your own user account.';
        break;
      default:
        message = 'Oops! Something went wrong. Try again later.';
        break;
    }

    return message;
  }
}


