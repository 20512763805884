import { B } from '@angular/cdk/keycodes';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable()
export class EventService {

  private instagramAuth = new BehaviorSubject(false);
  instagramAuthObs = this.instagramAuth.asObservable();

  constructor() { }

  onInstagramAuth(bool: boolean) {
    console.log('insta ', bool)
    this.instagramAuth.next(bool)
  }

}
