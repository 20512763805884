import { Injectable } from '@angular/core';
import {  Functions, httpsCallableData } from '@angular/fire/functions';
import { EMPTY, from, Observable, of } from 'rxjs';
import { concatMap, switchMap } from 'rxjs/operators';
import { Firestore,  } from '@angular/fire/firestore';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root',
})

export class SocialService {

  constructor(
    public afs: Firestore,
    private fns: Functions
  ) { }

  getIntstructorFacebookPosts(userId: string, instructorId: string) {
    const callable = httpsCallableData(this.fns,'social-getUserFBPosts');
    return callable({ userId: userId, instructorId: instructorId });
  }

  getInstagramContent(instructorId: string) {
    const callable = httpsCallableData(this.fns,'social-getInstagramPosts');
    return callable({ instructorId: instructorId });
  }
  public exchangecodeForShortLivedInstagramToken(userId: string, code: any): Observable<any> {

    console.log('social service ', userId, code);
    const callable = httpsCallableData(this.fns,'social-getInstagramShortLiveToken');
    return callable({
      userId: userId, code: code, redirect: `${environment.apiUrl}/instagram-auth-callback`
    });
  }
  public exchangeInstagramShortLivedToken(userId: string, shortAccessToken: string) {

    const callable = httpsCallableData(this.fns,'social-getInstagramLongLiveToken');
    return callable({ userId: userId, accessToken: shortAccessToken });
  }

  facebookLogin() {
    //login with facebook and return observable with fb access token on success
    return from(new Promise<fb.StatusResponse>(resolve => FB.login(resolve, { scope: 'email,user_birthday,user_posts,user_photos,user_link', return_scopes: true })))
      .pipe(concatMap(({ authResponse }) => {
        if (!authResponse) return EMPTY;
        return of(authResponse.accessToken);
      }));
  }

}
