import { EventEmitter, Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { ThriveNotification, AccountNotification, NotificationType } from "../models/notification.model";
import { BookingsService } from "./bookings.service";
import { UserService } from './user.service';

@Injectable({ providedIn: 'root' })
export class NotificationService {
  public addedAccountNotificationItemEmitter = new EventEmitter();
  public accountNotificationItems: ThriveNotification[] = [];
  public accountNotificationItemsSubject = new BehaviorSubject(this.accountNotificationItems);
  public accountNotificationItemsObservable = this.accountNotificationItemsSubject.asObservable();

  constructor(private bookingsService: BookingsService, private userService: UserService) {

  }

  async checkInstructorAccountNotifications(instructorId: string) {
    return new Promise(async resolve => {
      this.accountNotificationItems = [];
    const numberOfBookings = await this.bookingsService.getNumberofFutureInstructorBookingDates(instructorId);
    if (numberOfBookings === 0) {
      const noBookings = new AccountNotification();
      noBookings.type = NotificationType.NoBookingDates;
      this.accountNotificationItems.push(noBookings);
    }
   return  this.userService.getUser(instructorId).subscribe(user => {
      if (!user.profilePicture) {
        const noPic = new AccountNotification();
        noPic.type = NotificationType.NoProfilePicture;
        this.accountNotificationItems.push(noPic);
      }
      if (!user.bio) {
        const noBio = new AccountNotification();
        noBio.type = NotificationType.NoPersonalBio;
        this.accountNotificationItems.push(noBio);
      }
      if (!user.ratePerHour) {
        const noRate = new AccountNotification();
        noRate.type = NotificationType.NoHourlyRateSet;
        this.accountNotificationItems.push(noRate);
      }
  
      resolve( this.accountNotificationItems);
     });
    })
   
  
  }
}
