import { v4 as uuidv4 } from 'uuid';
export class Conversation {

    constructor() {
        this.id = uuidv4();
        this.docId = null;
    }
    id: string;
    docId: string;
    members: string[];
    messages: ConversationMessage[];
}

export class ConversationMessage {
    constructor() { }
    from: string;
    fromUserId: string;
    fromProfilePicture: string;
    isRead: boolean;
    subject: string;
    message: string;
    sentDate: Date;
    to: string;
    toUserId: string;
    conversationId?: string;
    conversationDocId?: string;
}