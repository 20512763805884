import { Injectable } from "@angular/core";
import { Firestore, addDoc, collection, collectionData, doc, docData, getDoc, limit, query, updateDoc, where, Timestamp, and, getDocs, orderBy } from "@angular/fire/firestore";
import { Booking } from "../models/booking.model";
import * as moment from 'moment';
import { combineLatest, lastValueFrom, map } from "rxjs";


@Injectable({
  providedIn: 'root',
})
export class BookingsService {

  constructor(
    public afs: Firestore

  ) { }

  getBooking(bookingDocId: string) {
    return docData(doc(this.afs, `bookings/${bookingDocId}`)).pipe(map(bookingDoc => {
      const booking = this.getBookingObj(bookingDoc, false);
      return booking;
    }));
  }

  async insertAvailbleDates(bookings: Array<Booking>) {
    // const db = this.afs.firestore;
    // const batch = db.batch()
    // try {
    //   bookings.forEach((booking) => {
    //     var docRef = db.collection("bookings").doc(); //automatically generate unique id
    //     batch.set(docRef, Object.assign({}, booking));
    //   });
    //   await batch.commit();

    // } catch (e) {
    //   console.log('create booking ', e);

    // }
  }

  async updateBooking(booking: Booking) {
    try {
      const bookingDocRef = doc(this.afs, `bookings/${booking.docId}`);
      await updateDoc(bookingDocRef, { ...booking });
    } catch (e) {
      console.log(e);
      return false;
    }
    return true;
  }

  createBooking(booking: Booking) {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const docRef = await addDoc(collection(this.afs, "bookings"), booking);
        console.log('new booking ', docRef.id);
        resolve(docRef.id);
      } catch (e) {
        console.log('create booking ', e);
        resolve(null);
      }
    });
  }

  async checkForDuplicateBooking(userId: string, instructorId: string, fromDate: Date, endDate: Date) {
    const fromDateT = Timestamp.fromDate(fromDate);
    const endDateT = Timestamp.fromDate(endDate);
    const data = await getDocs(query(collection(this.afs, "bookings"), and(where('studentId', '==', userId), where('instructorId', '==', instructorId), where('startDateTime', '==', fromDateT), where('startDateTime', '==', endDateT)), orderBy('startDateTime')));

    const bookings = [];
    data.forEach(qr => {
      if (qr.data().studentId && qr.data().studentId !== '') {
        const booking = this.getBookingObj(qr, false);
        bookings.push(booking);
      }
    });
    return bookings;

  }

  getAllBookingsForUser(studentId: string) {
    return collectionData(query(collection(this.afs, "bookings"), where('studentId', '==', studentId))).pipe(map(data => {
      const bookings = [];
      data.forEach(qr => {
        const booking = this.getBookingObj(qr, false);
        bookings.push(booking);
      });
      return bookings;
    }));

  }

  instructorOfUser(instructorId: string, studentId: string) {
    return collectionData(query(collection(this.afs, "bookings"), and(where('studentId', '==', studentId), where('instructorId', '==', instructorId)))).pipe(map(data => {
      const bookings = [];
      data.forEach(qr => {
        const booking = this.getBookingObj(qr, false);
        bookings.push(booking);
      });
      return bookings;
    }));
  }

  getAllBookingsForUserAndMonth(userId: string, start, end) {

    const fromDate = !start ? moment().startOf('month') : moment(start);
    const endDate = !end ? moment().endOf('month') : moment(end);
    const fromDateT = Timestamp.fromDate(fromDate.toDate());
    const endDateT = Timestamp.fromDate(endDate.toDate());
    const studentSnapShot = collectionData(query(collection(this.afs, "bookings"), and(where('studentId', '==', userId), where('startDateTime', '>=', fromDateT), where('startDateTime', '<=', endDateT))));
    const instructorSnapShot = collectionData(query(collection(this.afs, "bookings"), and(where('instructorId', '==', userId), where('startDateTime', '>=', fromDateT), where('startDateTime', '<=', endDateT))));

    return combineLatest([studentSnapShot, instructorSnapShot], (student, instructor) => ({ student, instructor })).pipe(map(pair => {
      const bookings = [];
      pair.student.forEach(qr => {
        const booking = this.getBookingObj(qr, false);
        bookings.push(booking);
      });
      pair.instructor.forEach(qr => {
        const booking = this.getBookingObj(qr, false);
        bookings.push(booking);
      });
      return bookings;
    }));

  }

  getUniqueInstructorBookings(userId: string) {
    return collectionData(query(collection(this.afs, "bookings"), where('instructorId', '==', userId)))

  }

  getAllBookingsCountForUser(userId: string) {

    const today = moment();
    today.hour(0);
    today.minute(0);
    today.second(0);
    const fromDateT = today.toDate();
    const now = moment();
    const studentSnapShot = collectionData(query(collection(this.afs, "bookings"), and(where('studentId', '==', userId), where('startDateTime', '>=', fromDateT))));
    const instructorSnapShot = collectionData(query(collection(this.afs, "bookings"), and(where('instructorId', '==', userId), where('startDateTime', '>=', fromDateT))));
    console.log('counts ')
    return combineLatest([studentSnapShot, instructorSnapShot], (student, instructor) => ({ student, instructor })).pipe(map(pair => {
      let bookingsCount = 0;
      pair.student.forEach(qr => {
        const booking = qr;
        if (moment(booking.startDateTime.toDate()).isAfter(now)) {
          bookingsCount += 1;
        }
      });
      pair.instructor.forEach(qr => {
        const booking = qr.data ? qr.data() : qr;
        if (moment(booking.startDateTime.toDate()).isAfter(now)) {
          bookingsCount += 1;
        }
      });
      return bookingsCount;
    }));
  }

  getNumberOfBookingWithInstructorAndStudent(userId: string, instructorId: string) {
    const bookingsSnapShot = collectionData(query(collection(this.afs, "bookings"), and(where('studentId', '==', userId), where('instructorId', '==', instructorId))));
    return bookingsSnapShot.pipe(map(data => {
      const bookings = [];
      data.forEach(ele => {
        const booking = this.getBookingObj(ele, false);
        bookings.push(booking);
      });
      return bookings;
    }))
  }

  getInstructorTimesForDate(instructorId: string, date: string) {
    return collectionData(query(collection(this.afs, "bookings"), and(where('instructorId', '==', instructorId), where('date', '==', date)))).pipe(map(data => {
      const bookings = [];
      data.forEach(qr => {
        const booking = this.getBookingObj(qr, false);
        bookings.push(booking);
      });
      return bookings;
    }));

  }

  getInstructorDates(instructorId: string, fromDate: Date, endDate: Date) {
    const fromDateT = Timestamp.fromDate(fromDate);
    const endDateT = Timestamp.fromDate(endDate);
    return collectionData(query(collection(this.afs, "bookings"), and(where('instructorId', '==', instructorId), where('startDateTime', '>=', fromDateT), where('startDateTime', '<=', endDateT)))).pipe(map(data => {
      const bookings = [];
      data.forEach(qr => {
        const booking = this.getBookingObj(qr, false);
        bookings.push(booking);
      });
      return bookings;
    }));

  }
  getInstructorBookingsInRangeStream(instructorId: string, fromDate: Date, endDate: Date, isAnonymous: boolean) {

    const fromDateT = Timestamp.fromDate(new Date(fromDate));
    const endDateT = Timestamp.fromDate(new Date(endDate));
    return collectionData(query(collection(this.afs, "bookings"), and(where('instructorId', '==', instructorId), where('startDateTime', '>=', fromDateT), where('startDateTime', '<=', endDateT)))).pipe(map(data => {
      const bookings = [];
      data.forEach(qr => {
        if (qr.studentId && qr.studentId !== '') {
          const booking = this.getBookingObj(qr, isAnonymous);
          bookings.push(booking);
        }
      });
      return bookings;
    }));

  }
  async getInstructorBookingsInRange(instructorId: string, fromDate: Date, endDate: Date, isAnonymous: boolean) {
    const fromDateT = Timestamp.fromDate(new Date(fromDate));
    const endDateT = Timestamp.fromDate(new Date(endDate));
    const data = await getDocs(query(collection(this.afs, "bookings"), and(where('instructorId', '==', instructorId), where('startDateTime', '>=', fromDateT), where('startDateTime', '<=', endDateT)), orderBy('startDateTime')));

    const bookings = [];
    data.forEach(qr => {
      if (qr.data().studentId && qr.data().studentId !== '') {
        const booking = this.getBookingObj(qr, isAnonymous);
        bookings.push(booking);
      }
    });
    return bookings;

  }

  async getStudentBookingsInRange(studentId: string, fromDate: Date, endDate: Date) {

    const fromDateT = Timestamp.fromDate(moment(fromDate).toDate());
    const endDateT = Timestamp.fromDate(moment(endDate).toDate());
    const colRef = collection(this.afs, "bookings");
    const queryRef = query(colRef, and(where('studentId', '==', studentId), where('startDateTime', '>=', fromDateT), where('startDateTime', '<', endDateT)), orderBy('startDateTime'));
    const data = await getDocs(queryRef);
    console.log(data.size, ' Student ', fromDateT, ' ', fromDate);
    const bookings = [];
    data.forEach(qr => {
      const booking = this.getBookingObj(qr, false);
      bookings.push(booking);
    });
    return bookings;
  }

  async getNumberofFutureInstructorBookingDates(instructorId: string) {
    const fromDateT = Timestamp.fromDate(new Date());

    const snapShot = await getDoc(doc(this.afs, `users/${instructorId}`));
    return snapShot.data()?.timeRanges.length;
  }
  async getFutureInstructorBookingDates(instructorId: string) {
    const bookings = [];
    const fromDateT = Timestamp.fromDate(new Date());

    return collectionData(query(collection(this.afs, "bookings"), and(where('instructorId', '==', instructorId), where('startDateTime', '>=', fromDateT)))).pipe(map(data => {
      const bookings = [];
      data.forEach(qr => {
        const booking = this.getBookingObj(qr, false);
        bookings.push(booking);
      });
      return bookings;
    }));

  }

  async getBookingsForStudent(studentId: string, resultsLimit: number) {

    return collectionData(query(collection(this.afs, "bookings"), and(where('studentId', '==', studentId), where('instructorId', '!=', '')), limit(resultsLimit))).pipe(map(data => {
      const bookings = [];
      data.forEach(qr => {
        const booking = this.getBookingObj(qr, false);
        bookings.push(booking);
      });
      return bookings;
    }));

  }

  getStudentBookingsForInstructor(instructorId:string, studentId:string) {
    return collectionData(query(collection(this.afs, "bookings"), and(where('instructorId', '==', instructorId),  where('studentId', '==', studentId)))).pipe(map(data => {
      const bookings = [];
      data.forEach(qr => {
        const booking = this.getBookingObj(qr, false);
        bookings.push(booking);
      });
      return bookings;
    }));
  }

  getBookingsWithNoSessionNotes(instructorId: string) {
    return collectionData(query(collection(this.afs, "bookings"), and(where('instructorId', '==', instructorId), where('notes', '==', ''), where('studentId', '!=', '')))).pipe(map(data => {
      const bookings = [];
      data.forEach(qr => {
        const booking = this.getBookingObj(qr, false);
        bookings.push(booking);
      });
      return bookings;
    }));

  }

  userHasBookingWithInstructor(userId: string, instructorId: string) {
    return collectionData(query(collection(this.afs, "bookings"), and(where('instructorId', '==', instructorId), where('studentId', '==', userId)))).pipe(map(data => {
      return data?.length == 0;
    }));

  }

  getBookingObj(qr, isAnonymous): Booking {
    const booking = new Booking();
    const data = qr?.data ? qr.data() : qr;

    booking.startDateTime = data.startDateTime.toDate();
    booking.endDateTime = data?.endDateTime.toDate();
    booking.dateStr = data.dateStr;
    booking.instructorId = data.instructorId;

    booking.bookedDate = data.bookedDate;
    if (data.id !== undefined) {
      booking.id = data.id;
    }
    booking.docId = data.docId;
    // if (!isAnonymous) {
    booking.studentId = data.studentId;
    booking.amount = data.amount;
    booking.instructorName = data.instructorName;
    booking.studentName = data.studentName;
    //}
    booking.notes = data.notes;
    booking.sessionStartTime = data?.sessionStartTime;
    booking.sessionDurationSeconds = data?.sessionDurationSeconds;
    return booking;
  }

  getBookingValueObj(qr, isAnonymous): Booking {
    const booking = new Booking();
    const data = qr?.data ? qr.data() : qr;

    booking.startDateTime = moment(data.startDateTime).toDate();
    booking.endDateTime = moment(data?.endDateTime).toDate();
    booking.dateStr = data.dateStr;
    booking.instructorId = data.instructorId;

    booking.bookedDate = data.bookedDate;
    booking.sessionStartTime = data?.sessionStartTime;
    booking.sessionDurationSeconds = data?.sessionDurationSeconds;

    if (!isAnonymous) {
      if (data.id !== undefined) {
        booking.id = data.id;
      }
      booking.docId = data.docId;
      booking.studentId = data.studentId;
      booking.amount = data.amount;
      booking.instructorName = data.instructorName;
      booking.studentName = data.studentName;
    }
    booking.notes = data.notes;
    booking.temporarilyBooked = data.temporarilyBooked;
    return booking;
  }
}
