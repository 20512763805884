import { AuthService } from './services/auth.service';

import { from, Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { Router } from "@angular/router";

export function initializeAppFactory(
  authService: AuthService,
  router: Router
) {
  return () => new Promise(resolve => {
    console.log('init app')
    authService
      .currentUser.subscribe(user => {
        resolve(1)
      });
  });
}
